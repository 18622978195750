import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFactory } from '@squareup/dex-data-shared-base-api';
import { getNextPublicExplorerGatewayURL } from '@squareup/dex-utils-environment';

import { endpointsFactory } from './endpoints';
import { EXPLORER_GATEWAY_API_REDUCER_PATH } from './explorer-gateway-api-constants';

const baseUrl = getNextPublicExplorerGatewayURL();

const createExplorerGatewayApi = () => {
  const explorerGatewayApi = createApi({
    baseQuery: baseQueryFactory({ baseUrl }),
    endpoints: (_builder) => ({}),
    reducerPath: EXPLORER_GATEWAY_API_REDUCER_PATH,
  });

  const injectedApi = explorerGatewayApi.injectEndpoints({
    endpoints: (builder) => endpointsFactory(builder),
  });

  // Store config
  const explorerGatewayApiStoreConfig = {
    middleware: explorerGatewayApi.middleware,
    reducer: explorerGatewayApi.reducer,
    reducerPath: explorerGatewayApi.reducerPath,
  };

  return { explorerGatewayApi: injectedApi, explorerGatewayApiStoreConfig };
};

let _explorerGatewayApi:
  | ReturnType<typeof createExplorerGatewayApi>
  | undefined;

const getApi = () => {
  if (_explorerGatewayApi) {
    return _explorerGatewayApi;
  }

  _explorerGatewayApi = createExplorerGatewayApi();
  return _explorerGatewayApi;
};

const getExplorerGatewayApi = () => {
  return getApi().explorerGatewayApi;
};

const getExplorerGatewayApiStoreConfig = () => {
  return getApi().explorerGatewayApiStoreConfig;
};

export { getExplorerGatewayApi, getExplorerGatewayApiStoreConfig };
