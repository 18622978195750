import {
  AnyAction,
  combineReducers,
  configureStore,
  EnhancedStore,
} from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { setOneTrustDenyByDefault } from '@square/onetrust-compliant-access';
import {
  clearErrors,
  setMobileNavState,
} from '@squareup/dex-data-shared-main-data-slices';
import {
  middleware as docsMiddleware,
  reducers as docsReducer,
} from '@squareup/dex-shell-docs-core';
import {
  reducers as sharedReducers,
  middlewares as sharedMiddleware,
} from '@squareup/dex-shell-main-shared';
import {
  middlewares as techRefMiddlewares,
  reducers as techRefReducer,
} from '@squareup/dex-tech-shell';
import { StoreStateNode } from '@squareup/dex-types-data-state';
import {
  AppConfigProps,
  ServerAppInitResults,
} from '@squareup/dex-types-shared-app';
import { Flags } from '@squareup/dex-types-shared-developer-api';
import { UserInfo } from '@squareup/dex-types-shared-developer-data-api';
import {
  ConsoleApplicationEventConsumer,
  SentryApplicationEventConsumer,
  TimingApplicationEventConsumer,
  WebApplicationEventConsumer,
  MartechApplicationEventConsumer,
  LoggingApplicationEventConsumer,
} from '@squareup/dex-utils-application-behavior-event-consumers';
import {
  configure as configureAppEvents,
  setRequestId as setAppEventsRequestId,
  publishUserAction,
  subscribe,
  UserEvent,
  TaskEvent,
  initTaskEvent,
} from '@squareup/dex-utils-application-behavior-events';
import {
  isPublicContext,
  getWindow,
  isServerContext,
  getNextPublicGATrackingID,
  isProductionPublicContext,
  isProductionEnvironment,
  getEnvironmentName,
} from '@squareup/dex-utils-environment';
import {
  publishActionableError,
  unhandledToInternalServerError,
} from '@squareup/dex-utils-error';
import { FeatureDetectionClient } from '@squareup/dex-utils-shared-feature-detection';
import { Router as RouterEventing } from 'next/router';

import { sentryConfig } from '../sentry.base.config';

const rootReducer = combineReducers({
  ...techRefReducer,
  ...docsReducer,
  ...sharedReducers,
});

type RootStoreState = ReturnType<typeof rootReducer>;
type Middlewares = [ThunkMiddlewareFor<RootStoreState>];
type RootStore = EnhancedStore<RootStoreState, AnyAction, Middlewares>;

type AppConfigResults<TStore> = {
  store: TStore;
  featureDetection: FeatureDetectionClient;
  requestId: string;
};

let previousRequestId: string | undefined;
let configResults: StoreStateNode<AppConfigResults<RootStore>> | undefined;

const navigationAction: UserEvent = {
  action: 'navigate',
  onType: 'document',
  onIdentifier: 'dex-tech-fe',
  context: 'global',
};

const taskEvent: TaskEvent = {
  context: 'application',
  action: 'task',
  onType: 'util',
  onIdentifier: 'configure',
  status: 'start',
};

function onRouteChangeComplete() {
  publishUserAction({
    ...navigationAction,
    extra: 'in_nav',
  });
}

function onBeforeunload() {
  publishUserAction({
    ...navigationAction,
    extra: 'leave',
  });
}

function onRouteChangeStart(store: RootStore) {
  // Clear errors at the start of page navigation
  store.dispatch(clearErrors());
  store.dispatch(setMobileNavState({ isOpen: false }));
}

/**
 * Hookup to all navigation events.
 */
function onNavigationStart(store: RootStore) {
  const win = getWindow();

  // Nothing to listen to.
  if (!win) {
    return;
  }

  publishUserAction({
    ...navigationAction,
    extra: 'enter',
  });

  RouterEventing.events.on('routeChangeStart', () => onRouteChangeStart(store));
  RouterEventing.events.on('routeChangeComplete', onRouteChangeComplete);
  win.addEventListener('beforeunload', onBeforeunload);
}

function configure(
  { requestId }: AppConfigProps,
  serverData?: ServerAppInitResults<UserInfo, Flags>['data']
) {
  let result: StoreStateNode<AppConfigResults<RootStore>>;
  const { end, start } = initTaskEvent(taskEvent, false);

  try {
    // Configure application behavior eventing.
    configureAppEvents({
      requestId,
    });

    // Dont process these to the wild
    if (!isProductionEnvironment()) {
      ConsoleApplicationEventConsumer(subscribe);
      TimingApplicationEventConsumer(subscribe);
    }

    if (isPublicContext()) {
      // Log to presidio server side and datadog client side
      LoggingApplicationEventConsumer(subscribe, {
        datadogClientToken: process.env.NEXT_PUBLIC_RUM_CLIENT_TOKEN || '',
        environment: getEnvironmentName(),
        appVersion: process.env.NEXT_PUBLIC_APP_BUILD_ID || '',
        service: 'dex-tech-fe',
      });

      // Tie application code eventing to those consumers that care.
      WebApplicationEventConsumer(subscribe, {
        applicationName: 'api-reference',
        enableLinkClickListener: true,
        gaTrackingId: getNextPublicGATrackingID(),
      });

      SentryApplicationEventConsumer(sentryConfig, subscribe);

      const martechOps = {
        countryCode: serverData?.getUserInfo?.data?.merchant?.country,
        userToken: serverData?.getUserInfo?.data?.merchant?.id,
      };

      MartechApplicationEventConsumer(subscribe, martechOps);
    }

    start();

    const featureDetection = new FeatureDetectionClient();

    // Configure One Trust to gate consent
    setOneTrustDenyByDefault(true);

    // Create our store
    const store = createStore();

    onNavigationStart(store);

    result = { featureDetection, store, requestId };

    end();
  } catch (error) {
    result = {
      error: unhandledToInternalServerError(error, 'configuring application'),
    };

    publishActionableError(result.error);
    end();
  }

  return result;
}

function createStore() {
  // Glue together the store here.
  return configureStore({
    devTools: !isProductionPublicContext(),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      ...techRefMiddlewares,
      docsMiddleware,
      ...sharedMiddleware,
    ],
    reducer: rootReducer,
  });
}

/**
 * State should only be shared within one page request.
 * - Server: Any new page request
 * - Browser: Intial page request
 */
function clearStatePerJsContext(requestId: string | undefined) {
  if (
    !previousRequestId ||
    (previousRequestId !== requestId && isServerContext())
  ) {
    forceClearBootState();
    previousRequestId = requestId;
  }
}

/**
 * Forces a clear of the boot state to allow for new configurations in testing.
 */
function forceClearBootState() {
  previousRequestId = undefined;
  configResults = undefined;
}

/**
 * Does all configuration of the application.
 * Configuration refers to intial boot strapping creating, setting relationships and
 * assigning subscriptions to application dependancy instances.
 * Application refers to both the server and browser instance responding to requests.
 *
 * NOTE: All module level state created here persists for the lifetime of the page.
 * - Server: Any new page request
 * - Browser: Intial page request
 */
function configureApp(
  { requestId }: AppConfigProps,
  serverData?: ServerAppInitResults<UserInfo, Flags>['data']
) {
  clearStatePerJsContext(requestId);

  if (!configResults) {
    configResults = configure({ requestId }, serverData);
  }

  // Make sure the active request ID is always up to date on eventing.
  setAppEventsRequestId(requestId);

  return configResults;
}

export { configureApp, type RootStore, navigationAction, forceClearBootState };
