import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import {
  GetAppSignaturesRequest,
  GetAppSignaturesResponse,
  CreateAppSignatureRequest,
  DeleteAppSignatureRequest,
} from '@squareup/dex-types-shared-mpsdk-app-signatures';
import {
  convertKeysToSnakeCase,
  convertKeysToCamelCase,
} from '@squareup/dex-utils-shared-object-key-utils';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';
import { DeveloperApiTagTypes } from './developer-api-tag-types';

const APP_SIGNATURES_URL = '/api/app_signatures';

const appSignaturesEndpointsFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  /**
   * Fetches all app signatures for an application.
   * GET /api/app_signatures/:id
   */
  getAppSignatures: builder.query<
    GetAppSignaturesResponse,
    GetAppSignaturesRequest
  >({
    query: ({ squareApplicationId }) => {
      return {
        path: `${APP_SIGNATURES_URL}/${squareApplicationId}`,
        method: HTTP_METHOD.GET,
      };
    },
    transformResponse: (response) => {
      return convertKeysToCamelCase(response);
    },
    providesTags: [DeveloperApiTagTypes.AppSignature],
  }),

  /**
   * Creates an app signature for an application.
   * POST /api/app_signatures
   */
  createAppSignature: builder.mutation<void, CreateAppSignatureRequest>({
    query: (body: CreateAppSignatureRequest) => {
      return {
        path: APP_SIGNATURES_URL,
        method: HTTP_METHOD.POST,
        additionalHeaders: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(convertKeysToSnakeCase(body)),
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.AppSignature],
  }),

  /**
   * Deletes an app signature for an application.
   * DELETE /api/app_signatures/:id/:signature_id
   */
  deleteAppSignature: builder.mutation<void, DeleteAppSignatureRequest>({
    query: ({ squareApplicationId, signatureId }) => {
      return {
        path: `${APP_SIGNATURES_URL}/${squareApplicationId}/${signatureId}`,
        method: HTTP_METHOD.DELETE,
      };
    },
    invalidatesTags: [DeveloperApiTagTypes.AppSignature],
  }),
});

const appSignaturesEndpoints = appSignaturesEndpointsFactory(endpointBuilder);

export { appSignaturesEndpoints, appSignaturesEndpointsFactory };
