import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFactory } from '@squareup/dex-data-shared-base-api';
import { getNextPublicConnectURL } from '@squareup/dex-utils-environment';

import { CONNECT_API_REDUCER_PATH } from './connect-api-constants';
import { endpointsFactory } from './endpoints';

const baseUrl = getNextPublicConnectURL();

const createConnectApi = () => {
  const connectApi = createApi({
    baseQuery: baseQueryFactory({ baseUrl }),
    endpoints: (_builder) => ({}),
    reducerPath: CONNECT_API_REDUCER_PATH,
  });

  const injectedApi = connectApi.injectEndpoints({
    endpoints: (builder) => endpointsFactory(builder),
  });

  // Store config
  const connectApiStoreConfig = {
    middleware: connectApi.middleware,
    reducer: connectApi.reducer,
    reducerPath: connectApi.reducerPath,
  };

  return { connectApi: injectedApi, connectApiStoreConfig };
};

let _connectApi: ReturnType<typeof createConnectApi> | undefined;

const getApi = () => {
  if (_connectApi) {
    return _connectApi;
  }

  _connectApi = createConnectApi();
  return _connectApi;
};

const getConnectApi = () => {
  return getApi().connectApi;
};

const getConnectApiStoreConfig = () => {
  return getApi().connectApiStoreConfig;
};

export { getConnectApi, getConnectApiStoreConfig };
