import {
  ThunkAction,
  Selector,
  AnyAction,
  EnhancedStore,
} from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { getAddOnsApi } from '@squareup/dex-data-shared-add-ons-api';
import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { getExplorerGatewayApiStoreConfig } from '@squareup/dex-data-shared-explorer-gateway-api';
import { shellViewStateStoreConfig } from '@squareup/dex-data-shared-main-data-slices';
import { combineReducers } from 'redux';

const {
  reducerPath: developerApiReducerPath,
  reducer: developerApiReducer,
  middleware: developerApiMiddleware,
} = getDeveloperApi();

const {
  reducerPath: addOnsApiReducerPath,
  reducer: addOnsApiReducer,
  middleware: addOnsApiMiddleware,
} = getAddOnsApi();

const explorerGatewayApiStoreConfig = getExplorerGatewayApiStoreConfig();

const reducers = {
  [shellViewStateStoreConfig.reducerPath]: shellViewStateStoreConfig.reducer,
  [developerApiReducerPath]: developerApiReducer,
  [addOnsApiReducerPath]: addOnsApiReducer,
  [explorerGatewayApiStoreConfig.reducerPath]:
    explorerGatewayApiStoreConfig.reducer,
};

const middlewares = [
  developerApiMiddleware,
  addOnsApiMiddleware,
  explorerGatewayApiStoreConfig.middleware,
];

const combinedReducers = combineReducers(reducers);

type SharedStoreState = ReturnType<typeof combinedReducers>;
type Middlewares = [ThunkMiddlewareFor<SharedStoreState>];
type SharedStore = EnhancedStore<SharedStoreState, AnyAction, Middlewares>;
type SharedStoreDispatch = SharedStore['dispatch'];
type SharedStoreSelector<T> = Selector<SharedStoreState, T>;

type SharedStoreAction<T> = ThunkAction<
  T,
  SharedStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

type SharedStoreAsyncAction<T> = ThunkAction<
  Promise<T>,
  SharedStoreState,
  undefined /* Extra args undefined for now*/,
  AnyAction
>;

// TODO: Create a generic shell for all this that can be used for any app.

export {
  reducers,
  middlewares,
  type SharedStoreSelector,
  type SharedStore,
  type SharedStoreDispatch,
  type SharedStoreAction,
  type SharedStoreAsyncAction,
  type SharedStoreState,
};
