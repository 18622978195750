import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';

import { CONNECT_API_REDUCER_PATH } from './connect-api-constants';

/**
 * All endpoints for the connect api.
 */
const endpointsFactory = (
  builder: BaseEndpointBuilder<typeof CONNECT_API_REDUCER_PATH>
) => ({
  revokeOAuthToken: builder.mutation<
    unknown,
    {
      accessToken: { client_id: string; merchant_id: string };
      clientSecret: string;
    }
  >({
    query: ({ accessToken, clientSecret }) => ({
      path: '/oauth2/revoke',
      method: HTTP_METHOD.POST,
      additionalHeaders: {
        Authorization: `Client ${clientSecret}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(accessToken),
      appName: 'oauth2',
    }),
  }),
  obtainOAuthToken: builder.mutation<
    // TODO: the full interface
    { access_token: string; expires_at: string },
    {
      accessToken: { client_id: string; refresh_token: string };
      clientSecret: string;
    }
  >({
    query: ({ accessToken, clientSecret }) => ({
      path: '/oauth2/token',
      method: HTTP_METHOD.POST,
      additionalHeaders: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...accessToken,
        client_secret: clientSecret,
        grant_type: 'refresh_token',
      }),
    }),
  }),
});
const endpoints = endpointsFactory(endpointBuilder);

export { endpoints, endpointsFactory };
