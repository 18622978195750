import { Action, EnhancedStore, SerializedError } from '@reduxjs/toolkit';
import { EndpointError } from '@square/ignition';
import {
  NullableStateNodeError,
  SliceError,
  StateNode,
  StateNodeError,
  StoreStateNode,
} from '@squareup/dex-types-data-state';
import { ServerAppInitResults } from '@squareup/dex-types-shared-app';
import { publishStateError } from '@squareup/dex-utils-application-behavior-events';
import { formatErrorToString } from '@squareup/dex-utils-error-formatting';
import {
  ERROR_HTTP_CODE_DESCRIPTIONS,
  FORBIDDEN_ERROR_CODE,
  INTERNAL_SERVER_ERROR_CODE,
  INTERNAL_SERVER_ERROR_MESSAGE,
  UNAUTHORIZED_ERROR_CODE,
} from '@squareup/dex-utils-shared-http-status';
import { NextPageContext } from 'next';

const CACHE = {
  MAX_AGE: '59',
  STALE_WHILE_REVALIDATE: '300',
};

type StatusAndMessage = { statusCode: number; message: string };

function hasErrorField(value: object): value is { error: string } {
  const error = (value as { error: string }).error;
  return error !== null && error !== undefined;
}

function hasDataField(value: object): value is { data: string } {
  const data = (value as { data: string }).data;
  return data !== null && data !== undefined;
}

function hasOriginalStatusField(
  value: unknown
): value is { originalStatus: unknown } {
  try {
    const { originalStatus } = value as { originalStatus: unknown };

    return originalStatus !== null && originalStatus !== undefined;
  } catch {
    return false;
  }
}

function isFetchSerializedError(value: unknown): value is SerializedError {
  return typeof (value as SerializedError).code === 'string';
}

function isFetchBasicError(value: unknown): value is { status: number } {
  return isNumber((value as { status: number }).status);
}

function isFetchParsingError(
  value: unknown
): value is { originalStatus: number } {
  return isNumber((value as { originalStatus: number }).originalStatus);
}

function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

function isHTTPErrorCode(code: number): boolean {
  return ERROR_HTTP_CODE_DESCRIPTIONS.has(code);
}

function isAuthnErrorCode(code: number | undefined) {
  return code === UNAUTHORIZED_ERROR_CODE || code === FORBIDDEN_ERROR_CODE;
}

/**
 * Returns true if the code is equal to or greater than an internal error.
 */
function isActionableHTTPErrorCode(code: number): boolean {
  return isHTTPErrorCode(code) && code >= INTERNAL_SERVER_ERROR_CODE;
}

/**
 * Alerts if the state node is in an error state and actionable.
 * This error may happen for 404s or other bad URLs which aren't worth
 * alerting on.
 */
function publishActionableError(stateNode: StateNode['error']) {
  if (
    stateNode &&
    (!hasOriginalStatusField(stateNode) ||
      isActionableHTTPErrorCode(stateNode.originalStatus))
  ) {
    publishStateError(stateNode);
  }
}

/**
 * Takes an error string and data producing an internal server error.
 */
function errorStringToInternalServerError(
  error: string,
  data?: string | undefined
): SliceError {
  return {
    data,
    error,
    originalStatus: INTERNAL_SERVER_ERROR_CODE,
    status: 'CUSTOM_ERROR',
  };
}

function serializedToInternalServerError(error: SerializedError): SliceError {
  return errorStringToInternalServerError(
    `${error.name} ${error.message}`,
    error.stack
  );
}

/**
 * Converts and unhandled exception into an internal server error.
 */
function unhandledToInternalServerError(
  error: unknown,
  errorContext: string
): SliceError {
  return errorStringToInternalServerError(
    formatErrorToString(error, errorContext)
  );
}

/**
 * Takes a promise result of a state node and unwraps it into a state node.
 * If a void value is returned from the fulfilled promise an error is returned.
 */
function settledResultToStoreStateNode<TData>(
  result: PromiseSettledResult<StoreStateNode<TData> | void>,
  callingContext: string
) {
  if (result.status === 'fulfilled') {
    return result.value || undefined;
  } else {
    return {
      error: unhandledToInternalServerError(result.reason, callingContext),
    };
  }
}

/**
 * Converts a general state error to a custom error.
 * If the state error provided is a serialization error
 * an internal server error will be created.
 */
function stateErrorToCustomError(
  error: StateNodeError,
  extraError: string | undefined = '',
  extraData: string | undefined = ''
): SliceError {
  if (isFetchSerializedError(error)) {
    return serializedToInternalServerError(error);
  } else {
    return {
      ...error,
      data: hasDataField(error) ? error.data : extraData,
      error: hasErrorField(error) ? error.error : extraError,
      status: 'CUSTOM_ERROR',
    };
  }
}

/**
 * Will interigate the given object for an error status code.
 * If no status code is found which is a number type the default
 * will be returned.
 *
 * The order is:
 *  - code
 *  - status
 *  - originalStatus
 */
function getErrorStatusAndMessage(
  error: StateNodeError,
  defaultStatusCode: number,
  defaultMessage: string
): StatusAndMessage {
  let errorResult = {
    statusCode: defaultStatusCode,
    message: defaultMessage,
  };

  if (isFetchSerializedError(error)) {
    errorResult = {
      ...errorResult,
      message: `Serialiation error: ${error.message || defaultMessage} code: ${
        error.code
      } name: ${error.name}`,
    };
  } else {
    const message = `${error.status || 'Fetch'} error: ${
      hasErrorField(error) ? error.error : defaultMessage
    }`;

    // Default to some fetch CUSTOM_ERROR or FETCH_ERROR
    errorResult = {
      message,
      statusCode: errorResult.statusCode,
    };

    if (isFetchBasicError(error)) {
      errorResult = {
        ...errorResult,
        statusCode: error.status,
      };
    } else if (isFetchParsingError(error)) {
      errorResult = {
        ...errorResult,
        statusCode: error.originalStatus,
      };
    }
  }

  // If the status code found is not a code we understand default back.
  if (!isHTTPErrorCode(errorResult.statusCode)) {
    errorResult.statusCode = defaultStatusCode;
  }

  return errorResult;
}

/**
 * Gets the error status code and message for the given state node.
 * If the error exist and is not a valid error status internal
 * server error is returned. Returns undefined is no error found.
 */
function getStateNodeErrorStatusAndMessage(maybeError: NullableStateNodeError) {
  let errorStatusAndMessage = undefined;

  /**
   * If we have an error node we interrigate it to find the code and message.
   */
  if (maybeError) {
    errorStatusAndMessage = {
      statusCode: INTERNAL_SERVER_ERROR_CODE,
      message: INTERNAL_SERVER_ERROR_MESSAGE,
    };

    errorStatusAndMessage = getErrorStatusAndMessage(
      maybeError,
      errorStatusAndMessage.statusCode,
      errorStatusAndMessage.message
    );
  }

  return errorStatusAndMessage;
}

function initialStateToResponse<
  TContext extends NextPageContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TStore extends EnhancedStore<unknown, Action<unknown>, any>,
  TState extends ReturnType<TStore['getState']>
>(
  { res }: TContext,
  store: TStore | undefined,
  serverAppInitResults: ServerAppInitResults<unknown, unknown>,
  errorSelector?: (_state: TState) => StateNode<TState>['error'],
  bootError?: StateNode<TState>['error']
) {
  const applicationError =
    bootError ||
    (errorSelector && store && errorSelector(store.getState() as TState));

  publishActionableError(applicationError);
  publishActionableError(serverAppInitResults.error);

  // Server initialization errors serve higher precedence than application errors.
  const result = {
    ...serverAppInitResults,
    ...(applicationError && { error: applicationError }),
    ...(serverAppInitResults.error && { error: serverAppInitResults.error }),
  };

  if (res) {
    const error = getStateNodeErrorStatusAndMessage(result.error);
    if (error) {
      // eslint-disable-next-line no-param-reassign
      res.statusCode = error.statusCode;
    } else {
      // Only valid status codes add cache headers
      // We will cache for 1m periods
      res.setHeader(
        'Cache-Control',
        `s-maxage=${CACHE.MAX_AGE}, stale-while-revalidate=${CACHE.STALE_WHILE_REVALIDATE}`
      );
    }
  }
  return result;
}

function getHttpCodeDescription(endpointError: EndpointError) {
  return endpointError.httpCode
    ? ERROR_HTTP_CODE_DESCRIPTIONS.get(endpointError.httpCode)
    : '';
}

export {
  hasDataField,
  hasOriginalStatusField,
  hasErrorField,
  getStateNodeErrorStatusAndMessage,
  isActionableHTTPErrorCode,
  isFetchSerializedError,
  isAuthnErrorCode,
  initialStateToResponse,
  publishActionableError,
  getHttpCodeDescription,
  errorStringToInternalServerError,
  stateErrorToCustomError,
  unhandledToInternalServerError,
  settledResultToStoreStateNode,
};
